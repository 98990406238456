import './App.css'

import * as React from 'react'

import { ReactComponent as LinkedinIcon } from './linkedin.svg'

const App: React.FC = () => {
  return (
    <>
      <div className={'hero-container'}>
        <div className={'hero'} id={'home'}>
          <div>
            <h1>Ying-Chun Wang</h1>
            <p>all the web stuff.</p>
          </div>
        </div>
      </div>
      <div className={'about-container'}>
        <div className={'about-content'}>
          <div className={'about-heading-container'}>
            <h3>Frontend engineer in nyc.</h3>
            <a
              href={'https://www.linkedin.com/in/ying-chun-wang-317ba9b0/'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <LinkedinIcon />
            </a>
          </div>
          <div className={'experience'}>
            <div className={'label'}>DoorDash</div>
            <div className={'title'}>Pickup, Hyperlocal & Scheduled Orders</div>
            <div className={'subtitle'}>Oct 2019 - Present</div>
            <div className={'description'}>
              Team has changed focus a couple of times, from pickup, hyperlocal,
              to scheduled orders. However, my focus and responsibility remain
              the same - lead and drive all the frontend web scope.
            </div>

            <ul>
              <li>NextJS</li>
              <li>GraphQL</li>
              <li>Typescript</li>
              <li>Express</li>
              <li>Node</li>
              <li>Redux</li>
              <li>Kotlin</li>
              <li>GRPC</li>
            </ul>
          </div>
          <div className={'experience'}>
            <div className={'label'}>Neuberger Berman</div>
            <div className={'title'}>Marketing</div>
            <div className={'subtitle'}>Jul 2018 - Oct 2019</div>
            <div className={'description'}>
              Eng team within the marketing org to develop and maintain the
              corporate site and the internal CMS system.
            </div>
            <ul>
              <li>Typescript</li>
              <li>Gulp</li>
              <li>SASS</li>
              <li>Node</li>
            </ul>
          </div>
          <div className={'experience'}>
            <div className={'label'}>Gensler</div>
            <div className={'title'}>Consulting</div>
            <div className={'subtitle'}>Jul 2016 - Jun 2018</div>
            <div className={'description'}>
              A bit all over the place, from developing CMS system to newsletter
              platform. Occasically do some backend work with PHP and MySQL.
            </div>
            <ul>
              <li>Vue</li>
              <li>Vuex</li>
              <li>Firebase</li>
              <li>SASS</li>
              <li>jQuery</li>
              <li>PHP</li>
              <li>MySQL</li>
            </ul>
          </div>
          <div className={'experience projects'}>
            <div className={'label'}>Fun Small Projects</div>
            <div className={'description'}>Side projects, mostly for fun. Click on each one of them for more details.</div>
            <ul>
              <li>
                <a
                  href={'https://slack-clone.yingchunwang.me/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  Slack Clone
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default App
